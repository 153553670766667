<template>
  <div id="page-user-list">
    <div class="vx-card p-6">
      <div class="flex flex-wrap items-center">
        <form>
          <h1>ตรวจสอบ สลิป Pompay</h1>
          <div v-if="!result" >

          <div class="my-5">
            <vs-input  class="w-full mt-4" label="รหัสอ้างอิง" v-model="referralCode"
            type="text" name="referralCode" />
          </div>
          <div class="mt-4">
          <vs-input class="w-full my-2" placeholder="กรุณาวางรูป" :value="selectedFile.name" @paste="onPaste"/>
          <br>
          <canvas  style="border:1px solid grey;" id="mycanvas" class="mt-3" width="300" height="300" ></canvas>
        </div>

          <vs-button
            :disabled="!$store.state.AppActiveUser.permissions.payment.action"
            color="success"
            type="filled"
            class="mt-2"
            @click="() => sendSlip()"
          >
            ตรวจสอบสลิป
          </vs-button>
          <vs-button
            :disabled="!$store.state.AppActiveUser.permissions.payment.action"
            color="primary"
            type="filled"
            class="ml-5"
            @click="() => clearData()"
          >
           ล้าง
          </vs-button>
          </div>


          <div v-if="result">
              <vs-input  class="w-full mt-4" label="จำนวนเงิน" v-model="result.data.amount"
                type="number" name="amount" readonly />
              <vs-input
                class="w-full my-2"
                label="รหัสอ้างอิง"
                readonly
                v-model="result.data.amount"
                type="text"
                name="amount"
              />
              <vs-input
                class="w-full my-2"
                label="รหัสธุรกรรม"
                readonly
                v-model="result.data.requestId"
                type="text"
                name="amount"
              />
              <!-- <img v-if="result.data.qrCodeData.qrType == 'qr'" :src="result.data.qrCodeData.qrCode" /> -->
              <div >
                <vs-input
                class="w-full my-2"
                label="เลขบัญชี"
                readonly
                v-model="result.data.bankAcc"
                type="text"
              />
              <vs-input
                class="w-full my-2"
                label="ชื่อบัญชี"
                readonly
                v-model="result.data.custName"
                type="text"
              />
              <vs-input
                class="w-full my-2"
                label="ธนาคาร"
                readonly
                v-model="result.data.bank_code"
                type="text"
              />
              </div>
              <vs-button
            :disabled="!$store.state.AppActiveUser.permissions.payment.action"
            color="success"
            type="filled"
            class="mt-2"
            @click="() => clearResult()"
          >
            กดเพื่อทำรายการอีกครั้ง
          </vs-button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '../../axios'

export default {
  components: {
  },
  data () {
    return {
      referralCode: null,
      result: null,
      selectedFile: []
    }
  },
  computed: {

  },
  watch: {

  },
  async mounted () {

  },
  methods: {
    async sendSlip () {
      const formData = new FormData()
      formData.append('slip', this.selectedFile)
      formData.append('referralCode', this.referralCode)  // appending file

      const result =  await (await axios.post('/pompay/checkslip', formData)).data

      if (!result.success) {
        this.$vs.notify({
          time: 10000,
          color: 'danger',
          position: 'top-right',
          icon: 'error',
          title: result.code,
          text: result.message
        })
        return
      }

      this.$vs.notify({
        time: 10000,
        color: 'success',
        position: 'top-right',
        icon: 'check',
        title: result.code,
        text: result.message
      })

      // Setup data
      this.result = result

      // Clear input data
      this.clearData()
    },
    async clearData () {
      this.selectedFile = []
      this.referralCode = null
      const canvas = document.getElementById('mycanvas')
      const context = canvas.getContext('2d')
      context.clearRect(0, 0, canvas.width, canvas.height)
      // await this.onPaste()
    },
    clearResult () {
      this.result = null
    },
    retrieveImageFromClipboardAsBlob (pasteEvent, callback) {
      if (pasteEvent.clipboardData === false) {
        if (typeof callback === 'function') {
          callback(undefined)
        }
      }

      const items = pasteEvent.clipboardData.items

      if (items === undefined) {
        if (typeof callback === 'function') {
          callback(undefined)
        }
      }

      for (let i = 0; i < items.length; i++) {
        // Skip content if not image
        if (items[i].type.indexOf('image') === -1) continue
        // Retrieve image on clipboard as blob
        const blob = items[i].getAsFile()

        if (typeof callback === 'function') {
          callback(blob)
          this.selectedFile = blob
        }
      }
    },
    onPaste (evt) {
      // console.log('on paste', evt)
      // Handle the event
      this.retrieveImageFromClipboardAsBlob(evt, function (imageBlob) {
        // If there's an image, display it in the canvas
        if (imageBlob) {
          const canvas = document.getElementById('mycanvas')
          const ctx = canvas.getContext('2d')

          // Create an image to render the blob on the canvas
          const img = new Image()

          // Once the image loads, render the img on the canvas
          img.onload = function () {
            // Update dimensions of the canvas with the dimensions of the image
            // Draw the resized image
            const resizedWidth = 300 // Adjust the resized width as needed
            const resizedHeight = (img.height / img.width) * resizedWidth

            canvas.width = resizedWidth
            canvas.height = resizedHeight

            ctx.drawImage(img, 0, 0, resizedWidth, resizedHeight)
          }

          // Crossbrowser support for URL
          const URLObj = window.URL || window.webkitURL

          // Creates a DOMString containing a URL representing the object given in the parameter
          // namely the original Blob
          img.src = URLObj.createObjectURL(imageBlob)
        }
      })
    }
  }
}
</script>
